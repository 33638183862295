import React from "react";
import { Box } from "@mui/material";

const AdBanner = ({ imageSrc }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", // Центрирование по горизонтали
        width: "100%", // Занимаем всю ширину колонки
      }}
    >
      <Box
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "240px",
          height: "400px",
          "@media (max-width: 900px)": {
            display: "none",
          },
        }}
      >
        <a
          href="https://geely-minsk.by/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: "100%", height: "100%" }}
        >
          <img
            src={imageSrc}
            alt="Рекламный баннер"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </a>
      </Box>
    </Box>
  );
};

export default AdBanner;
