import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import NewsCard from "../NewsCard";
import TitleWithLineAndDot from "../TitleWithLineAndDot";
import { useTheme, useMediaQuery } from "@mui/material";
import api from "../../api";

const EditorialPicks = () => {
  const theme = useTheme();
  const isBetween890And1040 = useMediaQuery(
    "(min-width:890px) and (max-width:1040px)"
  );
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page] = useState(1);

  const fetchStories = async (page) => {
    setLoading(true);
    try {
      const url = `/api/v1/stories/?ordering=-is_pinned_order,-score&pinned=true&page=${page}&page_size=3`;
      const response = await api.get(url);
      setStories((prevStories) => [...prevStories, ...response.data.results]);
    } catch (error) {
      console.error("Ошибка при загрузке сюжетов:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchStories(page);
  }, [page]);

  return (
    <Box>
      <Container>
        <Box mb={2}>
          <TitleWithLineAndDot
            title="Выбор редакции"
            fontSize={isBetween890And1040 ? "16px" : "20px"} // Условный размер шрифта
            lineHeight={6}
            fontWeight={700}
          />
        </Box>
      </Container>

      <Box>
        {loading && stories.length === 0 ? (
          <Grid container spacing={1}>
            {Array.from(new Array(3)).map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton variant="rectangular" width="100%" height={320} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
            sx={{
              "@media (min-width: 450px) and (max-width: 600px)": {
                "& > .MuiGrid-item": { flexBasis: "50%" }, // Устанавливаем ширину на 50% для экранов от 450 до 600 пикселей
                paddingLeft: 2, // Добавляем отступ слева
                paddingRight: 2, // Добавляем отступ справа
              },
              "@media (max-width: 450px)": {
                paddingLeft: 1, // Меньшие отступы для еще более узких экранов
                paddingRight: 1,
              },
            }}
          >
            {stories.length > 0 ? (
              stories.slice(0, 3).map((story) => (
                <Grid item xs={12} sm={6} md={12} lg={12} key={story.id}>
                  <Link
                    to={`/story/${story.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <NewsCard
                      item={{
                        title:
                          story.news_article.title || "Название отсутствует",
                        summary:
                          story.news_article.summary ||
                          "Краткое описание отсутствует",
                        description:
                          story.news_article.description ||
                          "Описание отсутствует",
                        main_image:
                          story.news_article.main_image ||
                          "https://via.placeholder.com/300x200",
                      }}
                      time={
                        story.news_article.publication_at || "Дата не указана"
                      }
                      theme={theme}
                      isLink
                    />
                  </Link>
                </Grid>
              ))
            ) : (
              <Typography variant="h6" align="center">
                Нет новостей для отображения.
              </Typography>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default EditorialPicks;
