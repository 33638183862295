import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, CircularProgress, Link } from '@mui/material';
import { AuthUserVerifyEmail, AuthUser } from './../../../api'; // Предполагаем, что AuthUser используется для повторной отправки кода
import { z } from 'zod';
import { useUser } from '../../../context/UserContext';

const codeSchema = z
  .string()
  .regex(/^\d{6}$/, { message: 'Код должен состоять из 6 цифр' });

const CodeVerificationForm = ({ email, onClose }) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const { setUser } = useUser();

  // Таймер для кнопки повторной отправки кода
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  // Обработка изменения в поле ввода кода
  const handleChange = (e) => {
    // Убираем пробелы при вводе
    const value = e.target.value.replace(/\s+/g, '');

    // Если длина больше 6, показываем ошибку и не даем ввести больше символов
    if (value.length > 6) {
      setError('Код должен состоять из 6 цифр');
      return;
    }

    // Если длина корректная и введены только цифры, убираем ошибку
    setError('');
    setCode(value);
  };

  // Отправка кода для проверки
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const validation = codeSchema.safeParse(code);
    if (!validation.success) {
      setError(validation.error.errors[0].message);
      setLoading(false);
      return;
    }

    try {
      const response = await AuthUserVerifyEmail({ email, code });
      if (response.status === 200 || response.status === 201) {
        setUser(response.data);
        onClose();
      }
    } catch (err) {
      setError('Неверный код или срок действия истек. Попробуйте еще раз.');
    } finally {
      setLoading(false);
    }
  };

  // Повторная отправка кода
  const handleResendCode = async () => {
    if (resendLoading || resendTimer > 0) return;

    setResendLoading(true);
    setError('');

    try {
      await AuthUser({ email });
      setResendTimer(60); // Устанавливаем таймер на 60 секунд
    } catch (err) {
      setError('Не удалось отправить код повторно. Попробуйте позже.');
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Проверочный код"
        variant="outlined"
        fullWidth
        margin="normal"
        value={code}
        onChange={handleChange}
        required
        error={!!error}
        helperText={error}
        inputProps={{ maxLength: 6 }} // Ограничение длины ввода на уровне атрибута
      />
      <Typography variant="body2" style={{ marginBottom: '20px', textAlign: 'right' }}>
        <Link
          component="button"
          onClick={handleResendCode}
          disabled={resendLoading || resendTimer > 0}
          underline="none"
          sx={{
            color: resendTimer > 0 ? 'text.disabled' : 'primary.main',
            cursor: resendTimer > 0 ? 'not-allowed' : 'pointer',
            pointerEvents: resendTimer > 0 ? 'none' : 'auto',
          }}
        >
          {resendTimer > 0
            ? `Повторно отправить код через ${resendTimer} сек.`
            : 'Отправить код повторно'}
        </Link>
      </Typography>
      <span style={{ display: 'block', margin: '10px 0', color: '#757575', fontSize: '14px' }}>
        Проверьте вашу почту <strong>{email}</strong>, а также папку <strong>Спам</strong> на наличие кода.
      </span>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        {loading ? 'Проверка...' : 'Проверить код'}
      </Button>
    </form>
  );
};

export default CodeVerificationForm;
