import React, { useRef, useEffect } from 'react';
import { Box, Avatar, Typography, Button } from '@mui/material';

const UserInfo = ({ user, handleEditProfile }) => {
  const nameRef = useRef(null); // Ссылка на элемент с именем пользователя

  useEffect(() => {
    // Проверяем, переполняется ли текст
    if (nameRef.current && nameRef.current.scrollWidth > nameRef.current.clientWidth) {
      // Можно добавить логику при переполнении текста, например, показывать всплывающее окно
    }
  }, [user.first_name, user.last_name]); // Запуск проверки при изменении имени

  return (
    <Box
      sx={{
        marginTop: '20px',
        borderRadius: '8px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Информация о пользователе */}
      {user && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mb: 4,
            width: '100%',
            '@media (max-width: 900px)': {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'left',
              width: '100%',
            },
          }}
        >
          <Avatar
            src={user.avatar || 'https://via.placeholder.com/150'}
            alt={user.username}
            sx={{
              width: 100,
              height: 100,
              mb: 2,
              '@media (max-width: 900px)': {
                width: 60,
                height: 60,
                mb: 0,
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              '@media (max-width: 900px)': {
                alignItems: 'flex-start',
                textAlign: 'left',
                marginLeft: '10px',
                flex: 1,
              },
            }}
          >
            <Typography
              variant="h6"
              ref={nameRef} 
              title={`${user.first_name} ${user.last_name}`} // Полное имя, чтобы показывать при наведении
              sx={{
                fontSize: '1rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px', // Устанавливаем ограничение ширины
                '@media (max-width: 900px)': {
                  fontSize: '0.875rem',
                  maxWidth: '150px', // Ограничиваем ширину для мобильных
                },
              }}
            >
              {`${user.first_name} ${user.last_name}`}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              title={user.email} // Полная почта, чтобы показывать при наведении
              sx={{
                fontSize: '0.875rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px', // Устанавливаем ограничение ширины
                '@media (max-width: 900px)': {
                  fontSize: '0.75rem',
                  maxWidth: '150px', // Ограничиваем ширину для мобильных
                },
              }}
            >
              {user.email}
            </Typography>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                px: 3,
                py: 1,
                fontSize: '1rem',
                '@media (max-width: 900px)': {
                  mt: 1,
                  px: 1,
                  py: 0,
                  fontSize: '0.875rem',
                  alignSelf: 'flex-start',
                },
              }}
              onClick={handleEditProfile}
            >
              Редактировать
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserInfo;
