import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TitleWithLineAndDot = ({ title, fontSize = '24px', lineHeight = 4, fontWeight = 700 }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        paddingBottom: `${lineHeight + 4}px`, // Добавляем отступ, чтобы линия была видимой
        display: 'inline-block',
      }}
    >
      <Typography
        variant="h5"
        fontWeight={fontWeight}
        sx={{
          fontSize: fontSize,
          display: 'inline-block',
          position: 'relative',
          '&:after': {
            content: '""',
            display: 'inline-block',
            width: '100%',
            height: `${lineHeight}px`,
            backgroundColor: '#d3d3d3',
            position: 'absolute',
            bottom: '-4px', // Ставим позицию четче, чтобы линия была видимой
            left: 0,
          },
          '&:before': {
            content: '""',
            display: 'inline-block',
            width: '5px',
            height: '5px', // Делаем точку чуть больше
            backgroundColor: '#d3d3d3',
            borderRadius: '50%', // Закругляем точку
            position: 'absolute',
            bottom: '-4px',
            right: '-12px',
          },
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

TitleWithLineAndDot.propTypes = {
  title: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.number,
  fontWeight: PropTypes.number,
};

export default TitleWithLineAndDot;
