import React, { useState } from 'react';
import { Box, Dialog, DialogContent, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material';

const ImageSliderModal = ({ mainImages }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClickOpen = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = () => {
    const newIndex = (currentIndex - 1 + mainImages.length) % mainImages.length;
    setSelectedImage(mainImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % mainImages.length;
    setSelectedImage(mainImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handleSwipe = (direction) => {
    if (direction === 'left') {
      handleNext();
    } else if (direction === 'right') {
      handlePrevious();
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        {(isMobileOrTablet ? mainImages.slice(0, 1) : mainImages.slice(0, 3)).map((image, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              cursor: 'pointer',
              width: isMobileOrTablet ? '100%' : '300px',
              height: '180px',
              backgroundColor: '#f0f0f0',
              overflow: 'hidden',
              position: 'relative',
            }}
            onClick={() => handleClickOpen(image, index)}
          >
            <img
              src={image}
              alt={`Thumbnail ${index}`}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                height: '100%',
                width: 'auto',
                objectFit: 'cover',
              }}
            />
            {index === 2 && mainImages.length > 3 && !isMobileOrTablet && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  padding: '10px',
                }}
              >
                +Еще фото
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {/* Модальное окно для увеличенного изображения */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          const touchStartX = e.changedTouches[0].clientX;
          e.currentTarget.ontouchend = (endEvent) => {
            const touchEndX = endEvent.changedTouches[0].clientX;
            if (touchEndX - touchStartX > 50) handleSwipe('right'); // свайп вправо
            if (touchStartX - touchEndX > 50) handleSwipe('left'); // свайп влево
          };
        }}
      >
        <DialogContent
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            padding: '0',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'fixed',
              right: 16,
              top: 16,
              color: '#fff',
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Стрелки для перехода между изображениями */}
          <IconButton
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              left: '20px',
              color: 'white',
              zIndex: 2,
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <img
            src={selectedImage}
            loading="lazy"
            alt="Selected Story"
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              height: 'auto',
              width: 'auto',
              borderRadius: '8px',
            }}
          />

          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: '20px',
              color: 'white',
              zIndex: 2,
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>

          {/* Миниатюры внизу экрана с горизонтальной прокруткой */}
          <Box
            sx={{
              position: 'fixed',
              bottom: '10px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: '5px',
              overflowX: 'auto', // Горизонтальная прокрутка
              zIndex: 3,
              padding: '5px 10px', // Добавим немного отступов для удобства
            }}
          >
            {mainImages.map((img, idx) => (
              <Box
                key={idx}
                onClick={() => {
                  setSelectedImage(img);
                  setCurrentIndex(idx);
                }}
                sx={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '4px',
                  backgroundImage: `url(${img})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  border: idx === currentIndex ? '2px solid white' : '2px solid transparent',
                  cursor: 'pointer',
                  flexShrink: 0, // Для предотвращения сжатия миниатюр при прокрутке
                }}
              />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageSliderModal;
