import React from 'react';
import DynamicTimeDisplay from '../DynamicTimeDisplay';
import { Box, Typography, Avatar, Grid } from '@mui/material';
import DropdownButton from '../DropdownButton';
import { useTheme, useMediaQuery } from '@mui/material';

const NewsHeader = ({ source, creationDate, title, shareUrl }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Grid container padding={1} spacing={0} alignItems="center">
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar
              src={source.favicon}
              alt="News Source"
              sx={{
                width: isMobile ? 15 : 15,
                height: isMobile ? 15 : 15,
                border: '1px solid gray',
                marginRight: isMobile ? '5px' : '5px',
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: isMobile ? '12px' : '14px',
                fontWeight: 600
              }}
            >
              {source.name}
            </Typography>
          </Box>
          
          {/* Кнопка "Поделиться" */}
          <DropdownButton shareUrl={shareUrl} title={title} />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h1"
            sx={{
              fontSize: isMobile ? '20px' : '24px',
              fontWeight: 700,
              paddingTop: isMobile ? '5px' : '5px',
            }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ paddingTop: isMobile ? '0px' : '0px' }}>
            <Typography
              variant="body2"
              sx={{
                color: '#adadad',
                fontWeight: 700,
                fontSize: isMobile ? '10px' : '12px',
              }}
            >
              <DynamicTimeDisplay creationDate={creationDate} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsHeader;
