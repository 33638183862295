import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, FormControlLabel, Checkbox, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailForm from './EmailForm';
import CodeVerificationForm from './CodeVerificationForm';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: 400 }, // Адаптация ширины для мобильных устройств
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: { xs: 2, sm: 4 }, // Адаптация внутренних отступов
    borderRadius: 2,
    overflowY: 'auto', // Добавляем прокрутку, если контент не помещается
    maxHeight: '90vh' // Ограничение максимальной высоты
};
const AuthModal = ({ onClose }) => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [acceptedPolicy, setAcceptedPolicy] = useState(true); // Чекбокс по умолчанию активен

    const handleEmailSubmit = (email) => {
        // Проверка, что чекбокс активен перед отправкой
        if (acceptedPolicy) {
            setEmail(email);
            setStep(2);
        } else {
            alert("Вы должны согласиться с политикой обработки данных, чтобы продолжить.");
        }
    };

    const handleCheckboxChange = (event) => {
        setAcceptedPolicy(event.target.checked);
    };

    const handleClose = () => {
        setStep(1);
        setEmail('');
        setAcceptedPolicy(true); // Сбрасываем состояние чекбокса при закрытии
        onClose();
    };

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="auth-modal"
            aria-describedby="auth-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" gutterBottom>
                    Войти или создать профиль
                </Typography>
                {step === 1 ? (
                    <>
                        <EmailForm onSubmit={handleEmailSubmit} />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={acceptedPolicy}
                                    onChange={handleCheckboxChange}
                                    name="acceptedPolicy"
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    Я соглашаюсь с{' '}
                                    <Link href="/data-processing-policy" target="_blank" rel="noopener">
                                        политикой обработки данных
                                    </Link>
                                </Typography>
                            }
                        />
                    </>
                ) : (
                    <CodeVerificationForm email={email} onClose={handleClose} />
                )}
            </Box>
        </Modal>
    );
};

export default AuthModal;