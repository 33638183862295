import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, Avatar, Typography, Button, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DynamicTimeDisplay from '../DynamicTimeDisplay';
import { Link } from 'react-router-dom'; // Импортируем Link

const NewsList = ({ stories, showSkeleton }) => {
  const [visibleCount, setVisibleCount] = useState(5);
  const theme = useTheme();

  useEffect(() => {
    setVisibleCount(5);
  }, [stories]);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  const renderSkeletons = () => {
    return Array.from({ length: 5 }).map((_, index) => (
      <ListItem
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '10px',
          padding: '5px 5px',
          marginBottom: '2px',
        }}
      >
        <Skeleton variant="circular" width={20} height={20} sx={{ marginRight: '8px' }} />
        <Skeleton variant="text" sx={{ flexGrow: 1, height: 20 }} />
      </ListItem>
    ));
  };

  return (
    <Box>
      <List sx={{ paddingTop: 2 }}>
        {showSkeleton
          ? renderSkeletons()
          : stories.slice(0, visibleCount).map((story) => (
              <ListItem
                key={story.id}
                alignItems="flex-start"
                component={Link} // Используем Link вместо "a"
                to={`/story/${story.id}`} // Путь для навигации
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                  borderRadius: '10px',
                  padding: '5px 5px',
                  marginBottom: '2px',
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {story.source && (
                      <Avatar
                        alt={story.source.name}
                        src={story.source.favicon || 'https://via.placeholder.com/20'}
                        sx={{
                          width: 20,
                          height: 20,
                          border: '1px solid #ccc',
                          marginRight: '8px',
                        }}
                      />
                    )}
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      sx={{ fontWeight: 500, marginRight: '8px', fontSize: '16px' }}
                    >
                      {story.source ? story.source.name : 'Источник неизвестен'}
                    </Typography>
                    <Typography component="span" variant="body2" color="textSecondary">
                      {story.creation_at ? (
                        <DynamicTimeDisplay creationDate={story.creation_at} />
                      ) : (
                        'Время не указано'
                      )}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body1"
                    fontWeight={600}
                    fontSize={14}
                    color="textPrimary"
                    sx={{ lineHeight: '1.2' }}
                  >
                    {story.title || 'Заголовок не указан'}
                  </Typography>
                </Box>
              </ListItem>
            ))}
      </List>

      {visibleCount < stories.length && !showSkeleton && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: -1 }}>
          <Button
            onClick={handleShowMore}
            variant="text"
            sx={{
              fontWeight: 'bold',
            }}
          >
            Показать Еще
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NewsList;
