import React, { useEffect, useState } from 'react';
import NewsSection from '../NewsSection';
import api from '../../api';

const UserRecommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasRecommendations, setHasRecommendations] = useState(false);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        // Запрос на получение рекомендаций пользователя
        const response = await api.get('/api/v1/news/user-recommendations/');
        if (response.data.length > 0) {
          setRecommendations(response.data);
          setHasRecommendations(true);
        } else {
          // Если нет рекомендаций, используем популярные сюжеты
          const popularResponse = await api.get('/api/v1/stories/popular');
          setRecommendations(popularResponse.data);
          setHasRecommendations(false);
        }
      } catch (error) {
        console.error('Ошибка при получении рекомендаций:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  if (isLoading) {
    return <div>Загрузка рекомендаций...</div>;
  }

  return (
    <NewsSection 
      type={hasRecommendations ? 'recommendations' : 'popular'} 
      stories={recommendations} 
    />
  );
};

export default UserRecommendations;
