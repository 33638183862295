import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Box, Grid, Typography, Skeleton } from "@mui/material";
import CategorySlider from "../components/CategoryPage/CategorySlider";
import NewsHeader from "../components/NewsDetailPage/NewsHeader";
import NewsMainContent from "../components/NewsDetailPage/NewsMainContent";
import RelatedArticles from "../components/NewsDetailPage/RelatedArticles";
import RelevantSideBar from "../components/RelevantSideBar/RelevantSideBar";
import RecommendationsWithAd from '../components/NewsDetailPage/RecommendationsWithAd';
import EditorialPicks from "../components/HomePage/EditorialPicks";
import { useCategories } from "../context/CategoriesContext";
import api from "../api";

const NewsDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { categories, loading: categoriesLoading } = useCategories();
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [imagesForSlider, setImagesForSlider] = useState([]);
  const [error, setError] = useState(null);

  // Функция для отслеживания активности пользователя
  const trackActivity = async (storyId) => {
    try {
      await api.post("/api/v1/user-activity/track_activity/", {
        story_id: storyId,
      });
      console.log("Активность пользователя зафиксирована");
    } catch (error) {
      console.error("Ошибка при фиксации активности пользователя:", error);
    }
  };

  useEffect(() => {
    const fetchStory = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/api/v1/story-detail/${id}/`);
        setStory(response.data);
        setSelectedArticle(response.data.news_articles[0]);
        setImagesForSlider([...response.data.main_images]);

        // Отслеживаем активность пользователя, если данные загружены
        if (response.data) {
          trackActivity(response.data.id);
        }
      } catch (error) {
        console.error("Ошибка при загрузке сюжета:", error);
        setError("Сюжет не найден.");
        setStory(null);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchStory();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [id]);

  // Обработчик для выбора категории
  const handleSelectCategory = (categoryId) => {
    // Перенаправление на страницу категории
    navigate(`/category/${categoryId}`);
  };

  if (!loading && !story) {
    return <Typography variant="h5" color="error">Сюжет не найден</Typography>;
  }

  return (
    <Container>
      {error && (
        <Typography variant="body1" color="error" align="center" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Box sx={{ marginTop: "40px", marginBottom: "20px" }}>
        {categoriesLoading ? (
          <Skeleton variant="rectangular" height={50} width="100%" />
        ) : (
          categories.length > 0 && (
            <CategorySlider
              categories={categories}
              selectedCategory={story?.news_articles[0]?.category?.id}
              onSelectCategory={handleSelectCategory} // Передаем функцию обработчика
            />
          )
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          {loading ? (
            <>
              <Skeleton variant="text" height={50} width="60%" sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" height={300} sx={{ mb: 2 }} />
              <Skeleton variant="text" height={20} width="100%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={20} width="100%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={20} width="100%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={20} width="80%" sx={{ mb: 2 }} />
            </>
          ) : (
            <>
              {selectedArticle && (
                <>
                  <NewsHeader
                    key={selectedArticle.id}
                    source={selectedArticle.source}
                    creationDate={selectedArticle.publication_at}
                    title={selectedArticle.title}
                    shareUrl={`https://zn.by/story/${id}`}
                  />

                  <Box mt={1}>
                    <NewsMainContent
                      mainImages={imagesForSlider}
                      text={selectedArticle.summary}
                      url={selectedArticle.url}
                      articleId={id}
                      loading={loading}
                    />
                  </Box>

                  <Box mt={4} mb={4}>
                    <RelatedArticles
                      articles={story.news_articles}
                      onArticleClick={() => {}}
                      selectedArticle={selectedArticle}
                    />
                  </Box>
                </>
              )}

              <Box marginBottom={2}>
                <RecommendationsWithAd type="recommendations" />
              </Box>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          <Box>
            {loading ? (
              <Skeleton variant="rectangular" height={400} />
            ) : (
              <RelevantSideBar
                excludeId={id}
                currentCategoryId={story?.news_articles[0]?.category?.id}
              />
            )}
          </Box>
          <Box
            sx={{
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              mt: { xs: 0, sm: 0, md: 2 },
              mb: 2,
            }}
          >
            {loading ? (
              <Skeleton variant="rectangular" height={200} width="100%" />
            ) : (
              <EditorialPicks />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewsDetailPage;
