import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../api';

const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      if (categories.length === 0) {
        try {
          const response = await api.get("/api/v1/categories/");
          setCategories(response.data);
        } catch (error) {
          console.error("Ошибка при загрузке категорий:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchCategories();
  }, [categories.length]);

  return (
    <CategoriesContext.Provider value={{ categories, loading }}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => useContext(CategoriesContext);
