import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getUser } from '../api'; 
import { useUser } from '../context/UserContext';

const PrivateRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { user, setUser } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          setIsAuthenticated(true);
        } else {
          const fetchedUser = await getUser();
          setUser(fetchedUser);
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user, setUser]); // Добавляем зависимости

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? element : <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;
