import React from 'react';
import { Card, Box } from '@mui/material';
import NewsImage from './NewsCard/NewsImage';
import NewsContent from './NewsCard/NewsContent';
import NewsFooter from './NewsCard/NewsFooter';
import { timeAgo } from '../utils/timeAgoUtils';

const NewsCard = ({ item, time, theme }) => (
  <Card
    sx={{
      height: '360px', 
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
      bgcolor: `${theme.palette.mode === 'dark' ? '#58585854' : '#ebebebff'}`,
      border: `1px solid ${theme.palette.mode === 'dark' ? '#b0b0b054' : 'transparent'}`,
      position: 'relative',
      transition: 'box-shadow 0.3s ease-in-out',
      '&:hover': {
        boxShadow: theme.palette.mode === 'dark'
          ? '0px 4px 12px rgba(180, 180, 180, 0.774)'
          : '0px 4px 12px rgba(0, 0, 0, 0.39)',
      },
      width: '100%',
      '@media (max-width: 600px)': {
        maxWidth: '100%',
        marginLeft: 0,
        marginRight: 0,
      },
      '@media (min-width: 350px) and (max-width: 450px)': {
        height: '400px', // Высота 400px для экрана от 350px до 450px
      },
      '@media (min-width: 800px) and (max-width: 900px)': {
        height: '400px', // Высота 400px для экрана от 800px до 900px
      },
    }}
  >
   
    <NewsImage
      image={item.main_image}
      category={item.category}
      sx={{ height: '180px', objectFit: 'cover', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
    />
    <Box sx={{ padding: '8px', flex: 1, overflow: 'hidden' }}> {/* Добавлен отступ и flex */}
      <NewsContent
        title={item.title}
        summary={item.summary}
        description={item.description}
        text={item.text}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      />
    </Box>
    <NewsFooter publicationDate={timeAgo(time)} />
  </Card>
);

export default NewsCard;
