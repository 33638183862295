import { React, useCallback, useEffect, useState } from "react";
import { Container, Grid, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import CategorySlider from "../components/CategoryPage/CategorySlider";
import NewsSection from "../components/NewsSection";
import EditorialPicks from "../components/HomePage/EditorialPicks";
import AdBanner from "../components/HomePage/AdBanner";
import api from "../api";

const CategoriesPage = () => {
  const { id } = useParams();
  const theme = useTheme();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editorialStoryIds, setEditorialStoryIds] = useState([]);

  const imageSrc = "/static/bg.png";


  useEffect(() => {
    api
      .get("/api/v1/categories/")
      .then((response) => {
        setCategories(response.data);
        if (!id && response.data.length > 0) {
          setSelectedCategory(response.data[0]);
        } else if (id) {
          const foundCategory = response.data.find(
            (cat) => cat.id === parseInt(id, 10)
          );
          setSelectedCategory(foundCategory);
        }
      })
      .catch((error) => console.error("Ошибка при загрузке категорий:", error));

    api
      .get(
        "https://zn.by/api/v1/stories/?ordering=-is_pinned_order,-score&pinned=true"
      )
      .then((response) => {
        if (response.data && response.data.results) {
          const storyIds = response.data.results.map((story) => story.id);
          setEditorialStoryIds(storyIds);
        } else {
          console.error(
            "Ошибка при загрузке историй редакции"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(
            "Ошибка сервера при загрузке историй редакции:",
            error.response.status
          );
        } else if (error.request) {
          console.error(
            "Запрос был отправлен, но ответа не получено:",
            error.message
          );
        } else {
          console.error("Ошибка при настройке запроса:", error.message);
        }
      });
  }, [id]);

  const handleSelectCategory = useCallback(
    (categoryId) => {
      const selectedCategory = categories.find(
        (category) => category.id === categoryId
      );
      setSelectedCategory(selectedCategory);
    },
    [categories]
  );

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        mb: 4,
        paddingLeft: { xs: 0, sm: 2 },
        paddingRight: { xs: 0, sm: 2 },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <CategorySlider
              categories={categories}
              onSelectCategory={handleSelectCategory}
              selectedCategory={selectedCategory?.id}
            />
            <Box mt={4}>
              <NewsSection selectedCategory={selectedCategory} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={3} sx={{ mt: "25px" }}>
          {/* Блок с рекламой */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 2
             
            }}
          >
            <AdBanner imageSrc={imageSrc} />
          </Box>

          {/* Редакционные выборы */}
          <Box
            sx={{
              borderRadius: "12px",
              paddingTop: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              mt: 2,
              mb: { xs: 2, sm: 0 },
              [theme.breakpoints.down("md")]: {
                mb: 2,
              },
            }}
          >
            <EditorialPicks storyIds={editorialStoryIds} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CategoriesPage;
