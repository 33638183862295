import React, { useState, useEffect, useCallback } from 'react';
import { Box, IconButton, Typography, Tooltip, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../../api';
import { useUser } from '../../context/UserContext';

const ReactionButtons = ({ articleId }) => {
  const [reactions, setReactions] = useState([]);
  const [userReactionId, setUserReactionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const theme = useTheme();
  const { user } = useUser();

  const fetchReactions = useCallback(async () => {
    try {
      const response = await api.get(`/api/v1/reactions/list/story/${articleId}/`);
      setReactions(response.data);
      const userReaction = response.data.find((reaction) => reaction.user_selected);
      setUserReactionId(userReaction ? userReaction.id : null);
    } catch (error) {
      console.error('Error fetching reactions:', error);
    } finally {
      setLoading(false);
    }
  }, [articleId]);

  useEffect(() => {
    if (articleId) {
      fetchReactions();
    }
  }, [articleId, fetchReactions]);

  const handleReactionClick = async (reaction) => {
    if (!user) {
      // Открываем тултип для неавторизованных пользователей
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000); // Закрываем через 2 секунды
      return;
    }

    // Обычная обработка для авторизованных пользователей
    try {
      if (reaction.id === userReactionId) {
        await api.post('/api/v1/reactions/remove/', {
          story_article: articleId,
          reaction_type: reaction.id,
        });
        setReactions((prevReactions) =>
          prevReactions.map((r) =>
            r.id === reaction.id ? { ...r, count: Math.max(0, r.count - 1) } : r
          )
        );
        setUserReactionId(null);
      } else {
        if (userReactionId) {
          await api.post('/api/v1/reactions/remove/', {
            story_article: articleId,
            reaction_type: userReactionId,
          });
          setReactions((prevReactions) =>
            prevReactions.map((r) =>
              r.id === userReactionId ? { ...r, count: Math.max(0, r.count - 1) } : r
            )
          );
        }
        await api.post('/api/v1/reactions/add/', {
          story_article: articleId,
          reaction_type: reaction.id,
        });
        setReactions((prevReactions) =>
          prevReactions.map((r) =>
            r.id === reaction.id ? { ...r, count: r.count + 1 } : r
          )
        );
        setUserReactionId(reaction.id);
      }
    } catch (error) {
      console.error('Error handling reaction:', error);
    }
  };

  return (
    <Tooltip
      title={!user ? 'Реакции доступны только авторизированным пользователям' : ''}
      open={!user && tooltipOpen}
      disableHoverListener
      disableFocusListener
      arrow
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width={20} height={20} />
            </Box>
          ))
        ) : (
          reactions.map((reaction) => (
            <Box key={reaction.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>
                <IconButton
                  onClick={() => handleReactionClick(reaction)}
                  sx={{
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
                  }}
                >
                  <img
                    src={reaction.icon}
                    alt={reaction.description}
                    style={{
                      width: 24,
                      height: 24,
                      filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none',
                    }}
                  />
                </IconButton>
              </span>
              <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                {reaction.count}
              </Typography>
            </Box>
          ))
        )}
      </Box>
    </Tooltip>
  );
};

export default ReactionButtons;
