import React, { useState } from 'react';
import {Button, TextField, Typography, Dialog, DialogContent, IconButton, Tabs, Tab, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import api from '../../../api';

const loginSchema = z.object({
  email: z.string().email('Введите корректный email'),
  password: z.string().min(6, 'Пароль должен содержать не менее 6 символов'),
});

const registrationSchema = z.object({
  username: z.string().min(3, 'Логин должен содержать не менее 3 символов'),
  email: z.string().email('Введите корректный email'),
  firstName: z.string().min(1, 'Введите ваше имя'),
  lastName: z.string().min(1, 'Введите вашу фамилию'),
});

const RegistrationForm = ({ dialogOpen, closeDialog }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  
  const { register: loginRegister, handleSubmit: handleLoginSubmit, formState: { errors: loginErrors } } = useForm({
    resolver: zodResolver(loginSchema),
  });

  const { register: registerRegister, handleSubmit: handleRegisterSubmit, formState: { errors: registerErrors } } = useForm({
    resolver: zodResolver(registrationSchema),
  });

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleLogin = async (data) => {
    try {
      const response = await api.post('/api/auth/login/', data);
      if (response.status === 200) {
        alert('Вы успешно вошли в систему!');
        closeDialog();
      }
    } catch (error) {
      console.error('Ошибка входа:', error);
      alert('Неверный email или пароль.');
    }
  };

  const handleRegister = async (data) => {
    if (!checkboxChecked) {
      alert('Необходимо согласие на обработку персональных данных.');
      return;
    }
    try {
      const response = await api.post('/api/auth/', { ...data });
      if (response.status === 201) {
        alert('Регистрация успешна. Пароль отправлен на email.');
        closeDialog();
      } else if (response.status === 200) {
        alert('Пользователь уже существует. Пароль отправлен на email.');
        closeDialog();
      }
    } catch (error) {
      console.error('Ошибка регистрации:', error);
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={closeDialog}>
      <DialogContent sx={{ p: 3, minWidth: '400px', position: 'relative' }}>
        <IconButton onClick={closeDialog} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>

        <Tabs value={activeTab} onChange={handleChangeTab} centered>
          <Tab label="Вход" />
          <Tab label="Регистрация" />
        </Tabs>

        {activeTab === 0 ? (
          <form onSubmit={handleLoginSubmit(handleLogin)}>
            <TextField
              {...loginRegister('email')}
              placeholder="Email"
              fullWidth
              variant="outlined"
              error={!!loginErrors.email}
              helperText={loginErrors.email?.message}
              sx={{ mb: 2 }}
            />
            <TextField
              {...loginRegister('password')}
              type="password"
              placeholder="Пароль"
              fullWidth
              variant="outlined"
              error={!!loginErrors.password}
              helperText={loginErrors.password?.message}
              sx={{ mb: 2 }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ py: 1.2, borderRadius: '20px', mt: 2 }}
            >
              Войти
            </Button>
          </form>
        ) : (
          <form onSubmit={handleRegisterSubmit(handleRegister)}>
            <TextField {...registerRegister('username')} placeholder="Логин" fullWidth variant="outlined" sx={{ mb: 2 }} />
            {registerErrors.username && <Typography color="error">{registerErrors.username.message}</Typography>}
            
            <TextField {...registerRegister('email')} placeholder="Email" fullWidth variant="outlined" sx={{ mb: 2 }} />
            {registerErrors.email && <Typography color="error">{registerErrors.email.message}</Typography>}
            
            <TextField {...registerRegister('firstName')} placeholder="Имя" fullWidth variant="outlined" sx={{ mb: 2 }} />
            {registerErrors.firstName && <Typography color="error">{registerErrors.firstName.message}</Typography>}
            
            <TextField {...registerRegister('lastName')} placeholder="Фамилия" fullWidth variant="outlined" sx={{ mb: 2 }} />
            {registerErrors.lastName && <Typography color="error">{registerErrors.lastName.message}</Typography>}
            
            <FormControlLabel
              control={<Checkbox checked={checkboxChecked} onChange={() => setCheckboxChecked(!checkboxChecked)} />}
              label="Регистрируясь, вы даете согласие на обработку персональных данных"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ py: 1.2, borderRadius: '20px', mt: 2 }}
              disabled={!checkboxChecked}
            >
              Регистрация
            </Button>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationForm;
