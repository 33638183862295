import React, { useState, useEffect } from 'react';
import { Box, Grid, CircularProgress, Container } from '@mui/material';
import UserRecommendations from './UserRecommendations';
import ProfileEditForm from './ProfileEditForm';
import EditorialPicks from '../../components/HomePage/EditorialPicks';
import CategoryNewsList from '../HomePage/CategoryNewsList';
import UserInfo from './UserInfo';
import { useUser } from '../../context/UserContext';
import { getUser, updateUser } from '../../api';

const ProfilePage = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser();
        setUser(userData);
      } catch (error) {
        console.error("Ошибка получения данных пользователя:", error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      fetchUser();
    }
  }, [loading, setUser]);

  const handleEditProfile = () => {
    setProfileMenuOpen(true);
  };

  const handleSaveProfile = async (updatedFields) => {
    // Подготавливаем данные для отправки
    const formData = new FormData();
    formData.append("first_name", updatedFields.first_name || ""); // пустая строка, если значение не указано
    formData.append("last_name", updatedFields.last_name || "");
    if (updatedFields.avatar instanceof File) {
      formData.append("avatar", updatedFields.avatar);
    }

    try {
      // Отправка данных на сервер с PATCH
      const updatedUser = await updateUser(formData, "PATCH"); // можно заменить на "PUT" для полного обновления
      
      // Обновляем локальное состояние с обновленными данными пользователя
      setUser((prevUser) => ({
        ...prevUser,
        ...updatedUser,
        avatar:
          updatedFields.avatar instanceof File
            ? URL.createObjectURL(updatedFields.avatar)
            : updatedFields.avatar,
      }));

      setProfileMenuOpen(false); // Закрываем форму редактирования
    } catch (error) {
      console.error("Ошибка при сохранении данных профиля:", error);
    }
  };

  const handleCloseEditModal = () => {
    setProfileMenuOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              order: { xs: 2, md: 1 },
            }}
          >
            <Box sx={{ mb: 4 }}>
              <CategoryNewsList />
            </Box>
            <Box sx={{ mb: 4 }}>
              <UserRecommendations />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            sx={{
              order: { xs: 1, md: 2 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                mb: 11,
                mt: 5,
                "@media (max-width: 900px)": {
                  mb: 0,
                  mt: 0,
                },
              }}
            >
              <UserInfo user={user} handleEditProfile={handleEditProfile} />
            </Box>

            <Box
              sx={{
                borderRadius: "8px",
                mt: 5,
                pt: 3,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                "@media (max-width: 900px)": {
                  mt: 0,
                  pt: 0,
                },
              }}
            >
              <EditorialPicks />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {isProfileMenuOpen && (
        <ProfileEditForm
          user={user}
          onClose={handleCloseEditModal}
          onSave={handleSaveProfile}
        />
      )}
    </Box>
  );
};

export default ProfilePage;
