import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import api from "../../api";
import RelevantItem from "./RelevantItem";
import TitleWithLineAndDot from "../TitleWithLineAndDot";

const RelevantSideBar = ({ excludeId, currentCategoryId }) => {
  const [relatedStories, setRelatedStories] = useState([]);

  useEffect(() => {
    const fetchRelatedStories = async () => {
      try {
        if (currentCategoryId) {
          const response = await api.get(`/api/v1/stories/?category=${currentCategoryId}&page_size=5`);
          
          // Фильтруем сюжеты, исключая текущий (excludeId)
          const filteredStories = response.data.results.filter(story => story.id !== parseInt(excludeId, 10));
          
          setRelatedStories(filteredStories);
        }
      } catch (error) {
        console.error("Ошибка при загрузке сюжетов:", error);
      }
    };

    fetchRelatedStories();
  }, [currentCategoryId, excludeId]); // Обновляемся при изменении currentCategoryId и excludeId

  return (
    <Box sx={{ width: "100%", paddingTop: { xs: 0, sm: 0, md: 1 } }}>
      <Box paddingTop={{ xs: 0, md: 1 }} paddingLeft={1}>
        <TitleWithLineAndDot
          title="Новости из категории"
          fontSize="20px"
          lineHeight={6}
          fontWeight={700}
        />
      </Box>
      <Box paddingTop={{ xs: 0, md: 1 }}>
        {relatedStories.length > 0 ? (
          relatedStories.map((story) => (
            <Box key={story.id} sx={{ marginBottom: "4px" }}>
              <RelevantItem story={story} />
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            Нет новостей для отображения.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default RelevantSideBar;