import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const NewsFooter = ({ publicationDate }) => {
  const theme = useTheme(); // Получаем текущую тему

  // Определяем цвет текста в зависимости от темы
  const textColor = theme.palette.mode === 'dark' ? '#ccc' : '#6e6e6e'; // Светло-серый для темной темы и темно-серый для светлой

  return (
    <Box
      sx={{
        padding: '5px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ ml: 'auto', textAlign: 'right', paddingRight: 1 }}>
        <Typography
          variant="caption"
          fontWeight={800}
          sx={{ color: textColor, fontSize: '0.75rem' }}
        >
          {publicationDate}
        </Typography>
      </Box>
    </Box>
  );
};

export default NewsFooter;
