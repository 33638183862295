import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import { AuthUser } from './../../../api';
import { z } from 'zod';

const emailSchema = z.string().email({ message: "Введите корректный email" });

const EmailForm = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError('');

      // Валидация email перед отправкой
      const validation = emailSchema.safeParse(email);
      if (!validation.success) {
          setError(validation.error.errors[0].message);
          setLoading(false);
          return;
      }

      try {
          const response = await AuthUser({ email });
          if (response.status === 200 || response.status === 201) {
              onSubmit(email);
          }
      } catch (err) {
          setError('Не удалось отправить код. Попробуйте еще раз.');
      } finally {
          setLoading(false);
      }
  };

  return (
      <form onSubmit={handleSubmit}>
        
          <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!!error}
              helperText={error}
          />
          <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
          >
              {loading ? 'Отправка...' : 'Отправить код'}
          </Button>
      </form>
  );
};

export default EmailForm;