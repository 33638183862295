import React from "react";
import { Box, Grid, Button, useMediaQuery, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LaunchIcon from "@mui/icons-material/Launch";
import ReactionButtons from "./ReactionButtons";
import ImageSliderModal from "./ImageSliderModal";

const NewsMainContent = ({ mainImages, text, url, articleId, loading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Проверка на мобильное устройство

  const updatedText = text.replace(/(https?:\/\/[^\s]+)/g, " $1"); // Простая функция добавления пробела перед ссылками

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={60} />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  fontSize: 14,
                  fontWeight: 500,
                  textAlign: {
                    xs: "left",
                    sm: "justify",
                  },
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: updatedText }} />
              </Box>
            )}
          </Box>
        </Grid>

        {/* Кнопка "Читать в первоисточнике" и реакции */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "row", // В мобильной версии - "reactions" сверху, кнопка снизу
            gap: 2,
            alignItems: "center",
          }}
        >
          {/* Компонент с реакциями */}
          <Box>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={400} />
            ) : (
              <ReactionButtons articleId={articleId} />
            )}
          </Box>

          {loading ? (
            <Skeleton
              variant="rectangular"
              width={isMobile ? "100%" : 200}
              height={40}
            />
          ) : (
            <Button
              variant="contained"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              fullWidth={isMobile}
              sx={{
                fontSize: "0.9rem",
                textTransform: "none",
                borderRadius: "5px",
                fontWeight: "700",
                backgroundColor: "#c7c7c783",
                color: "#000000",
                "&:hover": {
                  backgroundColor: "#a8a8a883",
                },
              }}
            >
              Читать в первоисточнике
              <Box ml={1} mt={0.5} color={"#00000042"}>
                <LaunchIcon sx={{ fontSize: "20px" }} />
              </Box>
            </Button>
          )}
        </Grid>

        {/* Отображение всех изображений в слайдере */}
        <Grid item xs={12}>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={200} />
          ) : (
            mainImages &&
            mainImages.length > 0 && (
              <ImageSliderModal mainImages={mainImages} />
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsMainContent;
