import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, Divider, Box, IconButton } from "@mui/material";

// Пути к SVG-иконкам для социальных сетей
const telegramIcon = "/static/telegram-svgrepo-com.svg";
const twitterIcon = "/static/x.svg";
const whatsappIcon = "/static/whatsapp-svgrepo-com.svg";
const vkIcon = "/static/vk-svgrepo-com.svg";
const viberIcon = "/static/viber-svgrepo-com.svg";
const okIcon = "/static/ok-svgrepo-com.svg";

const ShareButtons = ({ open, onClose, shareUrl, title }) => {
  const titleToShare = `${title}`;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: "#333", // Темный фон диалогового окна
          color: "#fff", // Белый текст для темной темы
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        Поделиться сюжетом
      </DialogTitle>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          padding: "16px",
          gap: { xs: "4px", sm: "8px" }, // Уменьшенное расстояние между иконками на мобильных устройствах
          overflow: "hidden",
        }}
      >
        {/* Twitter (X) */}
        <IconButton
          component="a"
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            shareUrl
          )}&text=${encodeURIComponent(titleToShare)}`}
          target="_blank"
          sx={{
            width: { xs: 40, sm: 50 },
            height: { xs: 40, sm: 50 },
            borderRadius: "8px",
            "&:hover": {
              transform: "scale(1.1)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)", // Увеличенная тень при наведении
            },
          }}
        >
          <Box
            component="img"
            src={twitterIcon}
            alt="Twitter"
            sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }}
          />
        </IconButton>

        {/* Telegram */}
        <IconButton
          component="a"
          href={`https://t.me/share/url?url=${encodeURIComponent(
            shareUrl
          )}&text=${encodeURIComponent(titleToShare)}`}
          target="_blank"
          sx={{
            width: { xs: 40, sm: 50 },
            height: { xs: 40, sm: 50 },
            borderRadius: "8px",
            "&:hover": {
              transform: "scale(1.1)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Box
            component="img"
            src={telegramIcon}
            alt="Telegram"
            sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }}
          />
        </IconButton>

        {/* WhatsApp */}
        <IconButton
          component="a"
          href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
            titleToShare + " " + shareUrl
          )}`}
          target="_blank"
          sx={{
            width: { xs: 40, sm: 50 },
            height: { xs: 40, sm: 50 },
            borderRadius: "8px",
            "&:hover": {
              transform: "scale(1.1)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Box
            component="img"
            src={whatsappIcon}
            alt="WhatsApp"
            sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }}
          />
        </IconButton>

        {/* VK */}
        <IconButton
          component="a"
          href={`https://vk.com/share.php?url=${encodeURIComponent(shareUrl)}`}
          target="_blank"
          sx={{
            width: { xs: 40, sm: 50 },
            height: { xs: 40, sm: 50 },
            borderRadius: "8px",
            "&:hover": {
              transform: "scale(1.1)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Box
            component="img"
            src={vkIcon}
            alt="VK"
            sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }}
          />
        </IconButton>

        {/* Viber */}
        <IconButton
          component="a"
          href={`viber://forward?text=${encodeURIComponent(
            titleToShare + " " + shareUrl
          )}`}
          target="_blank"
          sx={{
            width: { xs: 40, sm: 50 },
            height: { xs: 40, sm: 50 },
            borderRadius: "8px",
            "&:hover": {
              transform: "scale(1.1)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Box
            component="img"
            src={viberIcon}
            alt="Viber"
            sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }}
          />
        </IconButton>

        {/* Одноклассники */}
        <IconButton
          component="a"
          href={`https://connect.ok.ru/offer?url=${encodeURIComponent(
            shareUrl
          )}`}
          target="_blank"
          sx={{
            width: { xs: 40, sm: 50 },
            height: { xs: 40, sm: 50 },
            borderRadius: "8px",
            "&:hover": {
              transform: "scale(1.1)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Box
            component="img"
            src={okIcon}
            alt="Odnoklassniki"
            sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }}
          />
        </IconButton>
      </Box>
    </Dialog>
  );
};

ShareButtons.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shareUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ShareButtons;
