import React, { useEffect, useState } from "react";
import { Box, Grid, useTheme, Skeleton, Container } from "@mui/material";
import { Link } from "react-router-dom";
import api from "../../api";
import NewsCard from "../NewsCard";
import TitleWithLineAndDot from "../TitleWithLineAndDot";

const RecommendationsWithAd = ({ type, selectedCategory }) => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const imageSrc = "../static/Reclama-merk.png";

  useEffect(() => {
    const fetchStories = async () => {
      let url = `/api/v1/stories/?page=1&page_size=12`;
      if (type === "recommendations") url += "&type=recommendations";
      if (selectedCategory?.id) url += `&category=${selectedCategory.id}`;

      setLoading(true);
      try {
        const response = await api.get(url);
        const formattedStories = response.data.results.map((story) => ({
          id: story.id,
          category: story.category,
          creation_at: story.creation_at,
          articles: { ...story.news_article },
        }));
        setStories(formattedStories);
      } catch (error) {
        console.error("Ошибка при загрузке историй:", error);
      }
      setLoading(false);
    };
    fetchStories();
  }, [type, selectedCategory]);

  const renderSkeletons = () => (
    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
      {Array.from(new Array(3)).map((_, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Skeleton variant="rectangular" width="100%" height={320} />
          <Box sx={{ mt: 1 }}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="40%" />
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box>
      <Container sx={{ mb: 2 }}>
        <TitleWithLineAndDot
          title="Рекомендации для Вас"
          fontSize="25px"
          lineHeight={6}
          fontWeight={700}
        />
      </Container>

      <Box>
        {loading ? (
          renderSkeletons()
        ) : (
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            {stories.slice(0, 3).map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story.id}>
                <Link
                  to={`/story/${story.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <NewsCard
                    item={story.articles}
                    time={story.creation_at}
                    theme={theme}
                    isLink
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Рекламный блок */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            width: "728px",
            height: "90px",
            borderRadius: "0px",
            textAlign: "center",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f0f0f0",
            margin: "40px auto",
            "@media (max-width: 1050px)": {
              display: "none",
            },
          }}
        >
          <a
            href="https://меркаванне.бел/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: "100%", height: "100%" }}
          >
            <img
              src={imageSrc}
              alt="Рекламный блок"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
          </a>
        </Box>

        {/* Второй ряд рекомендованных новостей */}
        <Box mt={4}>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            {stories.slice(3, 6).map((story) => (
              <Grid item xs={12} sm={6} md={4} key={story.id}>
                <Link
                  to={`/story/${story.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <NewsCard
                    item={story.articles}
                    time={story.creation_at}
                    theme={theme}
                    isLink
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendationsWithAd;
