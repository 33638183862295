import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DynamicTimeDisplay from '../DynamicTimeDisplay';

const RelevantItem = ({ story }) => {
  return (
    <Box
      component={Link}
      to={`/story/${story.id}`}
      sx={{
        display: 'block',
        textDecoration: 'none',
        padding: '4px', // Уменьшенный внутренний отступ
        marginBottom: '4px', // Уменьшенный внешний отступ
        backgroundColor: 'background.paper',
        borderRadius: '8px',
        position: 'relative',
        '&:hover': {
          backgroundColor: '#0000000c',
        },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'bold',
          color: 'text.primary',
          marginBottom: '1.5em', // Уменьшенный отступ для текста
        }}
      >
        {story.news_article.title}
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          left: '5px',
          bottom: '4px', // Уменьшенное значение
        }}
      >
        <Typography>
          <DynamicTimeDisplay
            creationDate={story.news_article.publication_at}
            sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#999 !important' }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default RelevantItem;
