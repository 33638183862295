import React from 'react';
import { Box, Typography, CardContent } from '@mui/material';

const NewsContent = ({ title, summary, description, text }) => (
  <CardContent
    sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingBottom: '5px',
      paddingTop: '5px',
      paddingLeft: '5px',
      paddingRight: '5px',
      overflow: 'hidden',
    }}
  >
    <Box mb={1.5}>
      <Typography
        variant="h6"
        sx={{
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '1.2',
          whiteSpace: 'normal', // Убедиться, что текст переносится на новые строки
        }}
      >
        {title}
      </Typography>
    </Box>
    {summary && (
  <Typography
    variant="body2"
    sx={{
      fontSize: '13px',
      color: 'text.secondary',
      lineHeight: '1.1',
      display: '-webkit-box',         // Для поддержки многоточия на нескольких строках
      overflow: 'hidden',             // Скрываем лишний текст
      WebkitBoxOrient: 'vertical',    // Ориентируем строки вертикально
      textOverflow: 'ellipsis',       // Добавляем многоточие
      WebkitLineClamp: 3,             // Ограничиваем текст тремя строками (можно изменить количество строк)
    }}
  >
    {summary || text || description}
  </Typography>
)}
  </CardContent>
);

export default NewsContent;
