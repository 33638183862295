import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: 'https://zn.by/',
  withCredentials: true,
});


api.interceptors.response.use(
  (response) => {
    // eslint-disable-next-line no-unused-vars
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {


      originalRequest._retry = true;

      try {
        const refreshToken = Cookies.get('refresh-token');
        if (!refreshToken) {
          return Promise.reject(error);
        }
        
        return api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export const loginUser = (data) => api.post('/api/auth/login/', data);
export const registerUser = (data) => api.post('/api/auth/', data);
export const resetPassword = (email) => api.post('/api/auth/password-reset/', { email });

export const AuthUser = (data) => api.post('/api/auth/', data);
export const AuthUserVerifyEmail = (data) => api.post("/api/auth/verify/", data)



export const logoutUser = async () => {
  const response = await api.post('/api/auth/logout/');
  return response.data;
};

export const changePassword = async ({ new_password1, new_password2 }) => {
  const response = await api.post('/api/auth/password/change/', { new_password1, new_password2 });
  return response.data;
};

export const refreshToken = async (refresh) => {
  const response = await api.post('/api/auth/token/refresh/', { refresh });
  return response.data;
};

export const getUser = async () => {
  const response = await api.get('/api/auth/user/');
  return response.data;
};

export const updateUser = async (userData, method = 'PATCH') => {
  const response = await api({
    method: method,
    url: '/api/auth/user/',
    data: userData,
  });
  return response.data;
};


export const verifyToken = async (token) => {
  await axios.post('/api/token/verify/', { token });
};


export const authenticateUser = async (data) => {
  try {
    const response = await api.post('/login/', data);
    return response.data; // Успешная аутентификация, возвращаем данные пользователя
  } catch (error) {
    if (error.response && error.response.data) {
      // Если от API пришёл объект с ошибками, возвращаем его
      throw error.response.data; 
    } else {
      // Если произошла ошибка на уровне сети или сервера, выводим общее сообщение
      throw new Error('Произошла ошибка при подключении к серверу');
    }
  }
};


export default api;
