import React from 'react';
import { Box} from '@mui/material';

const NewsImage = ({ image, category }) => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      paddingTop: '66.67%', 
      overflow: 'hidden',
      borderRadius: '10px 10px 0 0', 
      backgroundColor: '#f0f0f0',
    }}
  >
    <img
      src={image || 'https://via.placeholder.com/150'} 
      loading='lazy'
      alt={category ? `${category.name} visual` : 'News visual'} 
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'cover',   
      }}
    />

    {category && (
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: '4px 8px',
          fontSize: '0.75rem',
          fontWeight: 'bold',
        }}
      >
        {category.name || 'Без категории'}
      </Box>
    )}
  </Box>
);

export default NewsImage;
