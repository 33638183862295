import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import ShareButtons from './ShareButtons';

function DropdownButton({ shareUrl, title }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="Поделиться" onClick={handleClickOpen}>
        <ShareIcon />
      </IconButton>
      <ShareButtons open={open} onClose={handleClose} shareUrl={shareUrl} title={title} />
    </div>
  );
}

export default DropdownButton;
