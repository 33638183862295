import React, { useEffect, useState, useCallback } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Container, Grid, Typography, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme,  useMediaQuery, styled, alpha } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EuroIcon from '@mui/icons-material/Euro';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import ThemeSwitcher from './ThemeSwitcher';
import HamburgerMenu from './HamburgerMenu';
import api from '../../api';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import { useNavigate } from 'react-router-dom';
import { useUser  } from '../../context/UserContext';
import AuthButton from './Auth/AuthButton'; 

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  border: '1px solid #dfe1e5',
  boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
  width: '100%',
  maxWidth: 800,
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    maxWidth: '70%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

  

const Header = () => {  
  const { user, setUser } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [weather, setWeather] = useState({ temperature: 0, description: '', city: '' });
  const [currencyRates, setCurrencyRates] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); 

 

  const currencyIcons = {
    USD: <AttachMoneyIcon sx={{ fontSize: isMobile ? 12 : 14 }} />,
    EUR: <EuroIcon sx={{ fontSize: isMobile ? 12 : 14 }} />,
    RUB: <CurrencyRubleIcon sx={{ fontSize: isMobile ? 12 : 14 }} />,
  };

  const verifyUser = useCallback(() => {
    api.get('/api/auth/user/')
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.log('Не удалось проверить пользователя:', error);
        setUser(null);
      });
  }, [setUser]);

  useEffect(() => {
    verifyUser();
    fetchWeather();
    fetchCurrencyRates();
    fetchCategories();
  }, [verifyUser]);

   // Устанавливаем обработчик изменения размера экрана
   useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchWeather = async () => {
    try {
      const response = await api.get('/api/v1/weather/current/');
      setWeather(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных погоды:', error);
    }
  };

  const fetchCurrencyRates = async () => {
    try {
      const response = await api.get('/api/v1/currency-rates/');
      setCurrencyRates(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных валютных курсов:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get('/api/v1/categories/');
      setCategories(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке категорий:', error);
    }
  };   

  const closeDialog = () => {
    setDialogOpen(false);
  };


  const getInitials = () => {
    if (!user) return '';
    const firstNameInitial = user.first_name ? user.first_name[0].toUpperCase() : '';
    const lastNameInitial = user.last_name ? user.last_name[0].toUpperCase() : '';
    return `${firstNameInitial}${lastNameInitial}`;
  };

  const handleLogout = () => {
    api.post('/api/auth/logout/')
      .then(() => {
        setUser(null); // Сбрасываем состояние пользователя при выходе
        setAnchorEl(null); // Закрываем меню
        navigate('/'); // Перенаправляем на главную страницу
      })
      .catch((error) => {
        console.error('Ошибка при выходе:', error);
      });
  };

  useEffect(() => {
    api.get('/api/auth/user/')
      .then((response) => {
        setUser(response.data); // Обновляем состояние пользователя, включая аватар
      })
      .catch((error) => {
        console.log('Не удалось загрузить данные пользователя:', error);
        setUser(null);
      });
  }, [setUser]);

  const logoStyle = {
    marginRight: 2,
    height: isMobile ? 30 : 60,
    paddingTop: isMobile ? 1 : 0,
    width: 'auto',
    filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none', // Инвертировать для темной темы
  };  
  

  return (
    <AppBar position="static" color="default" sx={{ paddingTop: '20px' }}>
      <Container maxWidth="lg">
      <div id="back-to-top-anchor"></div>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box  sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/">
              <Box
                component="img"
                sx={logoStyle}
                alt="Логотип"
                src={"../static/logo.png"}
              />
            </Link>
          </Box>
          {!isMobile && (
            <Search sx={{ bgcolor: 'background.default', color: 'text.primary', flex: 1 }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Поиск новостей..." inputProps={{ 'aria-label': 'search' }} />
            </Search>
          )}
<Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
  <ThemeSwitcher />
  {user ? (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
        sx={{ fontSize: isMobile ? 24 : 35 }}
      >
        {user.avatar ? (
          <Avatar src={user.avatar} alt={user.username} sx={{ width: 35, height: 35 }} />
        ) : (
          <Avatar sx={{ width: 35, height: 35 }}>{getInitials()}</Avatar>
        )}
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem 
            component={Link} 
            to="/profile" 
            onClick={() => setAnchorEl(null)}  // Закрываем меню при клике
          >
            Личный кабинет
        </MenuItem>
        <MenuItem onClick={handleLogout}>Выход</MenuItem>
      </Menu>
    </>
  ) : (
     <AuthButton/>
  )}
</Box>

          <Box>
            <HamburgerMenu categories={categories} />
          </Box>
          </Toolbar>

          <Grid container spacing={isMobile ? 1 : 3} sx={{ mb: 1, pt: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'flex-start' }, mb: isMobile ? 1 : 0 }}>
                <Box
                component="img"
                sx={{
                  width: 15, 
                  height: 15, 
                  marginRight: 1,
                  filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none'
                }}
                  src={weather.icon} 
                  alt={weather.description} 
                />
                <Typography variant="body1" fontSize={isMobile ? 12 : 14} fontWeight="bold">
                  {weather.temperature}° {weather.city}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, flexWrap: 'wrap' }}>
              {currencyRates.map((rate) => (
                <Box
                  key={rate.currency}
                  sx={{
                    display: rate.currency === 'USD' || screenWidth >= 320 ? 'flex' : 'none',
                    alignItems: 'center',
                    marginRight: 2,
                    mb: isMobile ? 1 : 0,
                  }}
                >
                  {currencyIcons[rate.currency]}
                  <Typography variant="body1" fontSize={isMobile ? 12 : 14} fontWeight="bold">
                    {rate.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <RegistrationForm isModal={true} dialogOpen={dialogOpen} closeDialog={closeDialog} />
    </AppBar>
  );
};

export default Header;