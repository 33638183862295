import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { Facebook, Instagram, X } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink } from 'react-router-dom';
import CookieConsent from './CookieConsent';

export default function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800],
        p: { xs: 3, md: 6 }, // Уменьшение отступов в мобильной версии
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={{ xs: 2, md: 5 }}> {/* Уменьшение расстояния между блоками на мобильных */}
          {/* Полезная информация */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom sx={{ mb: { xs: 1, sm: 2 } }}>
              Полезная информация
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 0.5, sm: 1 } }}>
              <RouterLink to="/privacy-policy" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" color="text.secondary">
                  Политика конфиденциальности
                </Typography>
              </RouterLink>
              <RouterLink to="/data-processing-policy" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" color="text.secondary">
                  Обработка данных
                </Typography>
              </RouterLink>
              <RouterLink to="/cookie-policy" style={{ textDecoration: 'none' }}>
                <Typography variant="body2" color="text.secondary">
                  Политика обработки файлов Cookie
                </Typography>
              </RouterLink>
            </Box>
          </Grid>

          {/* Контакты */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom sx={{ mb: { xs: 1, sm: 2 } }}>
              Контакты
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: { xs: 0.5, sm: 1 } }}>
              Проспект Победителей, д. 100, г. Минск, Беларусь
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: { xs: 0.5, sm: 1 } }}>
              Email: info@example.com
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: { xs: 0.5, sm: 1 } }}>
              Телефон: +375 29 123 45 67
            </Typography>
          </Grid>

          {/* Социальные сети */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom sx={{ mb: { xs: 1, sm: 2 } }}>
              Следите за нами
            </Typography>
            <Box sx={{ display: 'flex', gap: { xs: 0.5, sm: 1 } }}>
              <Link href="https://www.facebook.com/" color="inherit" aria-label="Facebook">
                <Facebook />
              </Link>
              <Link href="https://www.instagram.com/" color="inherit" aria-label="Instagram">
                <Instagram />
              </Link>
              <Link href="https://www.twitter.com/" color="inherit" aria-label="Twitter">
                <X />
              </Link>
            </Box>
          </Grid>
        </Grid>  

        {/* Copyright */}
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Все права защищены © "}
            <Link color="inherit" href="https://zn.by/">
              Ваш Сайт
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>      
      <CookieConsent />
    </Box>
  );
}
